Vue.component('homeCategorySectionComponent', {
    props: ['only_icons_view'],
    data:function(){
        return {
            selectedCategory:'',
            menuCategories:[],
            submenucounter2:0,
            submenucounter3:0,
            itemsInSubMenu: 15,
        }
    },
    mounted:function(){
        var self = this;
        var $window = $(window)[0];

        this.$nextTick(function () {
            wrapInitCategory();
        });

        $(self.$el).find(".sub").css('max-height', $window.innerHeight - 10)

        window.addEventListener("resize", function(e) {
            $(self.$el).find(".sub").css('max-height', $window.innerHeight - 10)
        });
    },
    methods:{
        tempCatHistory:function(cat,lvl){
            if(!cat)
                console.log('Category not Found')
            this.$store.dispatch('updateCategoryHistory', {temp:true,category:cat,lvl:lvl});
        },
        saveCatHistory:function(cat,subcat,lvl){
            if(this.$refs[cat.code][0])
                this.$refs[cat.code][0].setAttribute('style','display: none; visibility: hidden;');
            let objectFilter = {};
            objectFilter.name = uuidv4();
            objectFilter.type='globalClassification';
            objectFilter.condition = `x.Classification.split(',').indexOf('${subcat.code}')!=-1`;
            objectFilter.label = subcat.code;
            objectFilter.filterField = "Classification";
            this.$store.dispatch('addGlobalFilter', objectFilter);
            this.$store.dispatch('updateCategory', subcat);
            window.scrollTo(0, 0);
        },
        getSubCategoriesProxyTool:function(cat,categoryName){
            let result = null;
            try {
                if (cat && this.getName(cat) == categoryName)
                    result = cat.children;
                else {
                    for (let chcat of cat.children) {
                        let deepSearch = this.getSubCategoriesProxyTool(chcat, categoryName)
                        if (deepSearch) {
                            return deepSearch;
                        }
                    }
                }
                return result;
            }
            catch(e){
                console.log('Error in getSubCategoriesProxyTool')
            }
        },
        getSubCategories: function(categoryName){
            let cat_list =this.$store.getters.getCategories;
            for (let cat of cat_list) {
                let deepSearch = this.getSubCategoriesProxyTool(cat, categoryName);
                if (deepSearch)
                    return deepSearch
            }
            return [];
        },
        catMouseOver:function(event){
            let oldValue = this.selectedCategory;
            if(oldValue!=event) {
                let subMenu = this.getSubCategories(event)
                this.menuCategories = subMenu;
            }
            this.selectedCategory=event;

        },
        catMouseClear:function(event){
            //this.menuCategories =[];
        },
        isLeafCategory:function(cat){
            try {
                if (cat.leaf || cat.children.length == 0)
                    return true;
                return false;
            }
            catch(e){
                console.log('isLeadCategory Error')
            }
        },
        processSubMenuLvl2:function(){
            this.submenucounter2=this.submenucounter2+1;
        },
        processSubMenuLvl3:function(){
            this.submenucounter3=this.submenucounter3-1;
        },
        mouseOver: function(cat){
            console.log('Change Selected Category',cat)
            this.selectedCategory = cat;
        },
        getCategoryChilds:function(){
            if(this.$store.getters.getCategories.length>0)
                return __lodash__.orderBy(this.$store.getters.getCategories[0]['children'],'name','asc')
            else
                return [];
        },
        getName:(cat)=>{
            try{
                return cat.name
            }
            catch(err){
                console.log('Category without name',cat,err)
                return 'Category without Name'
            }

        },
    },
    template: `<div :class="{'filter-elements category-tree-container': true, 'col':true, 'm2':true, 's12':true, 'only-icons-view': only_icons_view}">
                  <ul id="menu-3" class="category-tree-menu">
                      <template v-for="(cat, index)  in getCategoryChilds()">
                          <li class="category-menu-link">
                             <ul :ref='cat.code' class="sub">
                                   <div>
                                      <div class="col s12">
                                          <div v-bind:class="['category-title category-color-'+(cat.code)]">
                                              <span class="titulomenu">{{getName(cat)}}</span>
                                          </div>
                                      </div>
                                      <div class="submenus" @mouseleave="selectedCategory=null">
                                          <div class="col s6" v-if="cat.children">
                                              <template v-for="subcat2 in cat.children">
                                                  <template v-if="!isLeafCategory(subcat2)">
                                                      <span  @mouseover="selectedCategory=subcat2;" v-bind:class="{'active': selectedCategory == subcat2}">
                                                          {{getName(subcat2)}}
                                                          <div class="label-3" @mouseleave="selectedCategory=null">
                                                              <template v-if="selectedCategory">
                                                                  <template  v-for="subcat3 in selectedCategory.children">
                                                                       <span >
                                                                          <router-link  v-on:click.native="saveCatHistory(cat,subcat3,3)"  :to="'/category/'+encodeURI(getName(subcat3).replace('/','%2F'))" >
                                                                             {{getName(subcat3)}}
                                                                          </router-link>
                                                                      </span>
                                                                  </template>
                                                              </template>
                                                          </div>
                                                      </span>
                                                  </template>
                                                  <template v-else>
                                                      <span v-if="isLeafCategory(subcat2)" @mouseover="selectedCategory=null">
                                                        <router-link @mouseover="selectedCategory=null;" v-on:click.native="saveCatHistory(cat,subcat2,2)" :to="'/category/'+encodeURI(getName(subcat2).replace('/','%2F'))" >
                                                            {{getName(subcat2)}}
                                                         </router-link>
                                                      </span>
                                                  </template>
                                              </template>
                                          </div>
                                      </div>
                                  </div>
                              </ul>
                             <div v-bind:class="['category-btn','category-btn-'+(cat.code) ]" @mouseover="tempCatHistory(cat,1)">
                                 <div id="flecha" v-bind:class="['category-arrow', 'category-arrow-'+(cat.code)]" ></div>
                                  <a href="#" v-bind:class="['category-menu-icon','category-icon-'+(cat.code)]" :style="'background-image: url(../ecommerce/api/get_cat_icon?catCode='+cat.code+');'"></a>
                                  <a href="#" v-bind:to="'/category/test'" data-activates="dropdown" class="btn category-menu-btn waves-effect waves-light" >
                                    {{getName(cat)}}
                                  </a>
                             </div>
                          </li>
                      </template>
                  </ul>
              </div>`,
});
